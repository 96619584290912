<template>
  <div class="my">
    <top-bar :title="'我的'" :bgc="'transparent'" :color="'#fff'"></top-bar>
    <nav-bar :id="1"></nav-bar>
    <img class="topbox" :src="userInfo.bannerImg" alt="">
    <div class="bottomInfo" :style="{height: isOld == 1 ? 'calc(100vh - 1.2rem)' : 'calc(100vh - 1.8rem)'}">
      <div class="user-back">
        <img class="topBack" :src="userInfo.newBack" alt=""/>
        <div class="user-top">
          <img :src="userInfo.defaultHeader" class="header" alt=""/>
          <div class="center">
            <div class="name" :style="{fontSize: isOld == 1 ? '22px' : '16px'}">{{myinfo.name}}</div>
            <div class="login-time" :style="{fontSize: isOld == 1 ? '16px' : '12px'}" v-if="myinfo.idNumber"><span v-if="isOld !== 1">身份证号：</span>{{myinfo.idNumber}}</div>
          </div>
          <img :src="isOld == 1 ? userInfo.oldCode : userInfo.code" class="code" @click="getQRCode" alt="" :style="isOld == 1 ? 'width:48px;height:48px' : 'width:44px;height: 44px;'"/>
        </div>
      </div>
      <van-cell-group :border="false" style="margin-bottom: 0.2rem;">
        <van-cell title="手机号码" :value="myinfo.mobile || '未知'" is-link @click="toTap(1)" :value-class="isOld == 1 ? 'oldMore':''" :title-class="isOld == 1 ? 'oldSize':'normalSize'">
          <template #icon>
            <img :class="isOld == 1 ? 'oldIcon':'msgIcon'" :src="isOld == 1 ? userInfo.oldPhone : userInfo.myMobile" alt="">
          </template>
        </van-cell>
        <van-cell title="更多信息" :value="'查看更多信息'" is-link @click="getinfo" :value-class="isOld == 1 ? 'oldMore':''" :title-class="isOld == 1 ? 'oldSize':'normalSize'">
          <template #icon>
            <img :class="isOld == 1 ? 'oldIcon':'msgIcon'" :src="isOld == 1 ? userInfo.oldMore : userInfo.myMore" alt="">
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group :border="false" style="margin-bottom: 0.2rem;">
        <van-cell title="我的房屋" value="" is-link @click="toTap(2)" :title-class="isOld == 1 ? 'oldSize':'normalSize'">
          <template #icon>
            <img :class="isOld == 1 ? 'oldIcon':'msgIcon'" :src="isOld == 1 ? userInfo.oldHouse : userInfo.myHouse" alt="">
          </template>
        </van-cell>
        <van-cell title="我的家人" value="" is-link @click="toTap(3)" :title-class="isOld == 1 ? 'oldSize':'normalSize'">
          <template #icon>
            <img :class="isOld == 1 ? 'oldIcon':'msgIcon'" :src="isOld == 1 ? userInfo.oldFamily : userInfo.myPeople" alt="">
          </template>
        </van-cell>
        <van-cell title="我的租客" value="" is-link @click="toTap(4)" :title-class="isOld == 1 ? 'oldSize':'normalSize'">
          <template #icon>
            <img :class="isOld == 1 ? 'oldIcon':'msgIcon'" :src="isOld == 1 ? userInfo.oldRent : userInfo.myRest" alt="">
          </template>
        </van-cell>
        <van-cell title="我的车辆" value="" is-link @click="toTap(5)" :title-class="isOld == 1 ? 'oldSize':'normalSize'">
          <template #icon>
            <img :class="isOld == 1 ? 'oldIcon':'msgIcon'" :src="isOld == 1 ? userInfo.oldCar : userInfo.myCar" alt="">
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group :border="false" @click="oldTab">
        <van-cell title="长辈模式" value="" style="height: 1.28rem;" :title-class="isOld == 1 ? 'oldSize':'normalSize'">
          <template #icon>
            <img :class="isOld == 1 ? 'oldIcon':'msgIcon'" :src="userInfo.myOld" alt="">
          </template>
          <template #right-icon>
            <img class="switch" :src="isOld == 1 ? userInfo.switchOpen : userInfo.mySwitch" alt="">
          </template>
        </van-cell>
      </van-cell-group>
      <div class="user-bottom">
        <div :class="isOld == 1 ?'oldTime':'loginTime'" v-if="myinfo.loginTime !== null">上次登录：{{myinfo.loginTime}}</div>
        <div class="unlink-btn" @click="unlink">解绑账号</div>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import navBar from '@/components/navBar/navBar'

import {getImageStream} from '@/utils/index'
import {desensitization} from '@/utils/utils'
import Vue from 'vue'
export default {
  data () {
    return {
      myinfo:{},
      isOld: null,
      userInfo:{
        bannerImg: getImageStream('files/wx/images/jmgg/myNewBack.png'),
        defaultHeader: getImageStream('files/wx/images/content/header-default.png'),
        code: getImageStream('files/wx/images/jmgg/myNewCode.png'),
        newBack: getImageStream('files/wx/images/jmgg/myNewCard.png'),
        myMobile: getImageStream('files/wx/images/jmgg/myNewMobile.png'),
        myMore: getImageStream('files/wx/images/jmgg/myNewMore.png'),
        myHouse: getImageStream('files/wx/images/jmgg/myNewHouse.png'),
        myPeople: getImageStream('files/wx/images/jmgg/myNewFamily.png'),
        myRest: getImageStream('files/wx/images/jmgg/myNewRest.png'),
        myCar: getImageStream('files/wx/images/jmgg/myNewCar.png'),
        myOld: getImageStream('files/wx/images/jmgg/myNewOld.png'),
        mySwitch: getImageStream('files/wx/images/jmgg/myNewSwitch.png'),
        switchOpen: getImageStream('files/wx/images/jmgg/switchOpen.png'),
        oldPhone: getImageStream('files/wx/images/jmgg/oldPhone.png'),
        oldMore: getImageStream('files/wx/images/jmgg/oldMore.png'),
        oldHouse: getImageStream('files/wx/images/jmgg/oldHouse.png'),
        oldFamily: getImageStream('files/wx/images/jmgg/oldFamily.png'),
        oldRent: getImageStream('files/wx/images/jmgg/oldRent.png'),
        oldCar: getImageStream('files/wx/images/jmgg/oldCar.png'),
        oldSwitch: getImageStream('files/wx/images/jmgg/oldSwitch.png'),
        oldCode: getImageStream('files/wx/images/jmgg/oldCode.png'),
      }
    }
  },
  components: {
    topBar,
    navBar
  },
  methods: {
    getinfo () {
      this.$router.push('/my-info')
    },
    getQRCode () {
      this.$router.push('/my-qrcode')
    },
    getjmgginfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/jmggInfo'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.$userId
        },false)
      }).then(({data})=> {
        if (data && data.code == 0) {
          console.log(data);
          this.myinfo = data.userInfoEntity
          this.myinfo.headImg = getImageStream(data.userInfoEntity.headImg)
          this.myinfo.mobile = desensitization(data.userInfoEntity.mobile)
          this.myinfo.idNumber = data.userInfoEntity.idNumber.replace(/(\w{6})\w*(\w{2})/, '$1******$2')
          this.isOld = data.userInfoEntity.extended ? JSON.parse(data.userInfoEntity.extended).isOld : null
        } else {
        this.$toast.fail(data.msg);
        }
      })
    },
    changeCommunity () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/list/community'),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: '',
          userId: this.$userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          if (data.list[1]) {
            Vue.prototype.$orgList = data.list
            this.$router.push('/community-change')
          }else {
            this.$toast.fail('没有获取到你在其他社区的信息');
          }
        } else {
        this.$toast.fail(data.msg);
        }
      })
    },
    toTap(type){
      if (type == 1) {
        this.$dialog.confirm({
          message: `是否切换手机号？`,
        }).then(() => {
          this.$router.push('/mobile-change')
        })
      } else if (type == 2) {
        this.$router.push('/houseManagement')
      } else if(type == 3) {
        this.$router.push('/familyManagement')
      } else if(type == 4) {
        this.$router.push('/tenantManagement')
      } else if(type == 5) {
        this.$router.push('/car')
      }
    },
    oldTab(){
      let tip = ''
      let isOld = this.isOld
      if(this.isOld && this.isOld !== 0) {
        tip = '正常版'
        isOld = 0
      } else {
        tip = '长辈版'
        isOld = 1
      }
      this.$dialog.confirm({
        message: `是否切换到${tip}？`,
      }).then(() => {
        this.getEdition(isOld)
      })
    },
    //保存选择版本
    getEdition(isCheck) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/setOldVersion'),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.$userId,
          version: isCheck
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          if (isCheck) {
            this.isOld = 1
            this.$router.push('/newHome')
          } else {
            this.isOld = 0
            this.$router.push('/newOldHome')
          }
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    unlink(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/unlink'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.$userId
        },false)
      }).then(({data})=> {
        if (data && data.code == 0) {
          this.$router.push('/login')
        } else {
          this.$toast.fail(data.msg);
        }
      })
    }
  },
  created () {
    this.isOld = null
    // document.querySelector('html').style.backgroundColor = '#387FF5'
    // document.querySelector('body').style.backgroundColor = '#387FF5'
    this.getjmgginfo()
  },
  beforeDestroy () {
    // document.querySelector('html').style.backgroundColor = ''
    // document.querySelector('body').style.backgroundColor = ''
  }
}
</script>

<style lang="scss" scoped>
.van-cell {
  color: #666666;
  font-size: 30px;
  padding: 24px 28px 24px 24px;
  .van-cell__title {
    padding-left: 24px;
    //line-height: 44px;
    //font-size: 32px;
    color: #666666;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}
.my {
  .topbox {
    //height: 250px;
    //padding-top: 140px;
    background-size: 100%;
    height: 490px;
  }
  .bottomInfo {
    //overflow-y: scroll;
    height: calc(100vh - 240px);
    padding-top: 24px;
    position: absolute;
    top: 390px;
    width: 100%;
    .user-back {
      position: relative;
      padding-bottom: 20px;
      background: #fff;
      border-radius: 40px 40px 0px 0px;
    }
    .topBack {
      width: 100%;
      height: 100%;
    }
    .user-top {
      position: absolute;
      top: 45px;
      left: 48px;
      width: 690px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      .header {
        width: 120px;
        height: 120px !important;
      }
      .center {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-left: 20px;
        flex: 1;
        padding: 5px 0;
        box-sizing: border-box;
        .name {
          font-size: 32px;
          line-height: 88px;
          color: #333;
          font-weight: 600;
        }
        .login-time {
          font-size: 24px;
          line-height: 34px;
          color: #999;
        }
      }
      .code {
        width: 88px;
        height: 88px;
        margin-right: 10px;
      }
    }
    .van-cell-group {
      .msgIcon {
        width: 40px;
        height: 40px;
      }
      .oldIcon {
        width: 56px;
        height: 56px;
      }
      .switch {
        width: 146px;
        height: 146px;
        transform: translateY(-43px);
      }
    }
    .user-bottom {
      position: relative;
      .loginTime {
        position: absolute;
        top: 46px;
        left: 188px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .unlink-btn {
        position: fixed;
        bottom: 200px;
        left: 30px;
        width: 690px;
        height: 88px;
        line-height: 88px;
        text-align: center;
        color: #fff;
        font-size: 32px;
        background-color: #85AEFF !important;
        border-radius: 20px !important;
      }
    }
  }
}
.oldSize {
  font-size:40px;
  line-height: 44px;
}
.oldMore {
  font-size: 36px;
}
.oldTime {
  position: absolute;
  top: 46px;
  left: 126px;
  font-size: 32px;
}
.normalSize {
  font-size: 32px;
  line-height: 66px;
}
</style>
